import React from 'react';
import { Spinner } from 'react-bootstrap';

function PageLoader() {
  return (
    <div className="loading-spinner-overlay">
     <div className="eater-container">
        
     </div>
    </div>
  );
}

export default PageLoader;
